import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { OnBoardedAppsModel} from '../modules/manage-app-id/manage-app-id.models';
import { DateTime } from 'aws-sdk/clients/devicefarm';
import { Environment } from './../../environments/environment';
import { DatePipe } from '@angular/common';
//import { Environment } from '@env/environment';


//const endpoint = 'https://sro-team-test.nikecloud.com/sre-ui-service/';

  @Injectable()
export class RestService {
  result:any;
  endpoint:string;
  okta_token_value: string;
  id_token:string;
  snass_api:string;
  
  constructor(@Inject(Environment) private env: Environment,private http: HttpClient,public datepipe: DatePipe) {
    this.endpoint=env.getEndpoint();
    this.snass_api=env.getSnassapi();
    const httpOptions = {
      headers: new HttpHeaders({
        'okta-token':  this.id_token
      })
    };
  }
  
  private extractData(res: Response) {
    let body = res;
    return res || { };
  }
  // private handleError(err: HttpErrorResponse) {
  //   let body = err.error;
  //   return body || { };
  // }
  getProducts(): Observable<any> {
    return this.http.get(this.endpoint+'fetch/v1').pipe(
      map(this.extractData));
  }


  getidtoken():string
  {
    this.id_token=sessionStorage.getItem('id_token');
    //this.id_token=this.okta_token_value.substring(this.okta_token_value.indexOf('=')+1,this.okta_token_value.indexOf('&'));
    return this.id_token;
  }
  getuseraccessdata(emailid:string): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchuserbyid/v1/'+emailid,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getuserstatsdatabyid(emailid:string): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchuserstatsdatabyid/v1/'+emailid,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getalluseraccessdata(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchuseraccess/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getdashboardcountdata(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchdashboardcountdata/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getteamsdata(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchteams/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getteamassocdata(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchteamassoc/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getservicesdata(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchservices/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getuserstatsdata(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchuserstats/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getappnamesdata(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchappnames/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getonlyappnamesdata(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchonlyappnames/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getsplunkdata(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchsplunkdata/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getsignalfxdata(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchsignalfxdata/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getcloudwatchdata(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchcloudwatchdata/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getnewrelicdata(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchnewrelicdata/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getincidentreportdata(startdate:DateTime,enddate:DateTime): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchincidentreportdata/v1/'+startdate+'/'+enddate,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getupdatedincidentreportdata(startdate:DateTime,enddate:DateTime): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchupdatedincidentreportdata/v1/'+startdate+'/'+enddate,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getcreatedincidentcount(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchcreatedincidentcount/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getupdatedincidentcount(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchupdatedincidentcount/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getteamscount(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchteamscountdata/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getservicescount(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchservicescountdata/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getappidcount(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchappidcountdata/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getsplunkonboardcount(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchsplunkcountdata/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getsignalfxonboardcount(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchsignalfxcountdata/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getcloudwatchonboardcount(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchcloudwatchcountdata/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getnewreliconboardcount(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchnewreliccountdata/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getsnaasonboardcount(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchsnaascountdata/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getslackonboardcount(): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchslackcountdata/v1/',{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  insertuserinuserstats(emailid:string): Observable<any> {
    try{
      let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
      return this.http.put(this.endpoint+'insertuserinuserstats/v1/'+emailid,"Inser user stats",{headers:headers}).pipe(
        map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
    }
    catch(e){
      console.log(e);
    }
  }
  insertsplunkconfig(splunkconfig): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'insertsplunkconfig/v1/',splunkconfig,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  insertsignalfxconfig(signalfxconfig): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'insertsignalfxconfig/v1/',signalfxconfig,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  insertnewrelicconfig(newrelicconfig): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'insertnewrelicconfig/v1/',newrelicconfig,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  insertcloudwatchconfig(cloudwatchconfig): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'insertcloudwatchconfig/v1/',cloudwatchconfig,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  insertteamapp(teamappconfig): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'insertteamappnames/v1/',teamappconfig,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  updateteamapp(teamname:string,appname:string): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'updateteamappnames/v1/'+teamname+'/'+appname,"Insert",{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  insertteam(teamconfig): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'insertteamnames/v1/',teamconfig,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  insertservice(serviceconfig): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'insertservicenames/v1/',serviceconfig,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  insertuserdtls(userdtls): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'insertuserdetails/v1/',userdtls,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  insertapinames(apiconfigdata:OnBoardedAppsModel): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'insertapinames/v1',apiconfigdata,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
   getappnamesdataforuser(userid:string): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.get(this.endpoint+'fetchappnamesbyuser/v1/'+userid,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  updatemanageuser(manageuser): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'updatemanageuser/v1/',manageuser,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  updatesplunkconfig(splunkconfig): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'updatesplunkconfig/v1/',splunkconfig,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  updatesignalfxconfig(signalfxconfig): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'updatesignalfxconfig/v1/',signalfxconfig,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  updatenewrelicconfig(newrelicconfig): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'updatenewrelicconfig/v1/',newrelicconfig,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  updatecloudwatchconfig(cloudwatchconfig): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'updatecloudwatchconfig/v1/',cloudwatchconfig,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  updateuservisitcount(username:string): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.put(this.endpoint+'updateuservisitcount/v1/'+username,"Insert",{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  updateuserinsertcount(username:string): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'updateuserinsertcount/v1/'+username,"Insert",{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  updateuserupdatecount(username:string): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
      return this.http.put(this.endpoint+'updateuserupdatecount/v1/'+username,"Insert",{headers:headers}).pipe(
        map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
      }
  getcreatedincidentcountbasedonuserapp(params): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
      return this.http.put(this.endpoint+'getcreatedincidentcountbasedonuserapp/v1/',params,{headers:headers}).pipe(
        map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
  }
  getupdatedincidentcountbasedonuserapp(params): Observable<any> {
    let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'getupdatedincidentcountbasedonuserapp/v1/',params,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
getsplunkonboardcountbasedonuserapp(params): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'getsplunkonboardcountbasedonuserapp/v1/',params,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
getsignalfxonboardcountbasedonuserapp(params): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'getsignalfxonboardcountbasedonuserapp/v1/',params,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
getnewreliconboardcountbasedonuserapp(params): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'getnewreliconboardcountbasedonuserapp/v1/',params,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
getcloudwatchonboardcountbasedonuserapp(params): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'getcloudwatchonboardcountbasedonuserapp/v1/',params,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
getsnaasonboardcountbasedonuserapp(params): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'getsnaasonboardcountbasedonuserapp/v1/',params,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
getincidentreportdatabasedonuserapp(params): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
    return this.http.put(this.endpoint+'getincidentreportdatabasedonuserapp/v1/',params,{headers:headers}).pipe(
      map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
deletesplunkconfig(configid:string): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.delete(this.endpoint+'deletesplunkconfig/v1/'+configid,{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
deletesignalfxconfig(configid:string): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.delete(this.endpoint+'deletesignalfxconfig/v1/'+configid,{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
deletecloudwatchconfig(configid:string): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.delete(this.endpoint+'deletecloudwatchconfig/v1/'+configid,{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
deletenewrelicconfig(configid:string): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.delete(this.endpoint+'deletenewrelicconfig/v1/'+configid,{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
getslackconfigidformessagebased(): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.get(this.endpoint+'getslackconfigid/v1/',{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
updateslackruleconfig(slackconfig): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.put(this.endpoint+'updateslackruleconfig/v1/',slackconfig,{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
expresspostdata(params): Observable<any> {
  return this.http.post('http://localhost:9000/index/token/v1/',params).pipe(
    map(this.extractData));
}
//get jira data
getjiradata(startdate:DateTime,enddate:DateTime): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.get(this.endpoint+'fetchjiradata/v1/'+startdate+'/'+enddate,{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
//get splunk count
getsresplunkcountdatabasedondate(startdate:DateTime,enddate:DateTime): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.get(this.endpoint+'fetchsplunkcountdatabasedondate/v1/'+startdate+'/'+enddate,{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
//get signalfx count
getsresignalfxcountdatabasedondate(startdate:DateTime,enddate:DateTime): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.get(this.endpoint+'fetchsignalfxcountdatabasedondate/v1/'+startdate+'/'+enddate,{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
//get cloudwatch count
getsrecloudwatchcountdatabasedondate(startdate:DateTime,enddate:DateTime): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.get(this.endpoint+'fetchcloudwatchcountdatabasedondate/v1/'+startdate+'/'+enddate,{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
//get newrelic count
getsrenewreliccountdatabasedondate(startdate:DateTime,enddate:DateTime): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.get(this.endpoint+'fetchnewreliccountdatabasedondate/v1/'+startdate+'/'+enddate,{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
//get catchpoint count
getcatchpointcountdatabasedondate(startdate:DateTime,enddate:DateTime): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.get(this.endpoint+'fetchcatchpointcountdatabasedondate/v1/'+startdate+'/'+enddate,{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
//get pagerduty count
getpdcountdatabasedondate(startdate:DateTime,enddate:DateTime): Observable<any> {
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.get(this.endpoint+'fetchpdcountdatabasedondate/v1/'+startdate+'/'+enddate,{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
// getsreincidentcountbyteam(startdate:DateTime,enddate:DateTime,teamname:string): Observable<any> {
//   let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
//   return this.http.get(this.endpoint+'fetchincidentcountbyteams/v1/'+startdate+'/'+enddate+'/'+teamname,{headers:headers}).pipe(
//     map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
// }
//hit snass api and get snow data
// getsredata(startdate:DateTime,enddate:DateTime): Observable<any> {
//   let start_date =this.datepipe.transform(startdate, 'yyyy-MM-dd');
//   let end_date =this.datepipe.transform(enddate, 'yyyy-MM-dd');
//   let headers=new HttpHeaders().set('appID', 'SRE_SLACKBOT_MENU').set('Content-Type', 'application/json').set('Authorization','Basic c2xhY2tib3Rfc25hYXM6JDFhKGshQjB0UEAkJFByMEQ').set('param','number,u_rb_name,u_rf_name,sys_created_on,state,priority');
//   let body={
//     "serviceType": "inc",
//     "createdbefore": end_date.toString() +" 00:00:00",
//     "createdafter": start_date.toString() +" 00:00:00",
//     "caller_id":"7d8e05e9db29ab007f879bc5ca961960"
//     };
//   return this.http.post(this.snass_api,body,{headers:headers}).pipe(
//     map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
// }
//get snaas data
getsnaasdata(startdate:DateTime,enddate:DateTime): Observable<any> {
  let start_date =this.datepipe.transform(startdate, 'yyyy-MM-dd');
  let end_date =this.datepipe.transform(enddate, 'yyyy-MM-dd');
  let headers=new HttpHeaders().set('okta-token', 'Bearer '+ this.getidtoken());
  return this.http.get(this.endpoint+'fetchsnaasdata/v1/'+start_date.toString()+'/'+end_date.toString()+'/',{headers:headers}).pipe(
    map(this.extractData),catchError(((error: HttpErrorResponse) => throwError(error.error))));
}
  private handleError(error: any) 
{
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
}
}
