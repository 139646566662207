import { Component,Injectable } from '@angular/core';

@Injectable()
export class Globals{

  invalidRole:string= "You are not authorized to view this page. Please contact #retail_sro slack channel for access";
  invalidAuthMsg:string = "Invalid Authentication details received. Re-Launch app from Okta";
  invalidSession:string = "Session Expired. Re-Launch app from Okta";
  userLoggedout:string = "You have been logged out.";
  origin_splunk:string="splunk";
  origin_signalfx:string="signalfx";
  origin_cloudwatch:string="cloudwatch";
  origin_newrelic:string="newrelic";
  origin_snaas:string="snaas";
  origin_slack:string="slack";
  piechart_splunk:string="SPLUNK";
  piechart_signalfx:string="SIGNALFX";
  piechart_newrelic:string="NEWRELIC";
  piechart_slack:string="SLACK";
  requestedbyemail:string="A.SROCHATBOT@nike.com";
  requestedbylocation:string="Victory(ED)";
  contact_type:string="Event Monitoring";
}
