import { Injectable,Inject} from '@angular/core';
import * as AWS from 'aws-sdk';
import { SplunkConfigModel} from '../modules/splunk-on-board/splunk-on-board.models';
import { SignalfxConfigModel} from '../modules/signalfx-on-board/signalfx-on-board.models';
import {NewRelicConfigModel} from '../modules/newrelic-on-board/newrelic-on-board.models';
import { CloudwatchConfigModel} from '../modules/cloudwatch-on-board/cloudwatch-on-board.models';
import { OnBoardedAppsModel} from '../modules/manage-app-id/manage-app-id.models';
import { ManageTeamModel } from '../modules/manageteam/manageteam.models';
import { AssociateTeamAppsModel } from '../modules/associateteamapps/associateteamapps.models';
import { ManageServiceModel } from '../modules/manageservice/manageservice.models';
import { ManageUserModel } from '../modules/manageuser/manageuser.models';
import { Environment } from './../../environments/environment';
import { AuthService }               from '../service/auth.service';
import {UserstatisticsService} from '../service/userstatistics.service';
import {RestService} from '../service/rest.service';

@Injectable()
export class InsertdataService {
    // splunkconfigtablename:string;
    incidentconfigtablename:string;
    apiaccesstablename:string;
    teamtablename:string;
    usertablename:string;
    servicetablename:string;
    teamapptablename:string;
    isActiveval:Boolean;
    endpoint:string;
    constructor(@Inject(Environment) private env: Environment,private restservice:RestService,
    @Inject(UserstatisticsService) private userstatisticsService:UserstatisticsService,
    @Inject(AuthService) private authService: AuthService) { 
        this.incidentconfigtablename=env.getIncidentConfigTable();
        this.apiaccesstablename = env.getApiAccessTable();
        this.teamtablename = env.getTeamTable();
        this.usertablename = env.getUserTable();
        this.servicetablename = env.getServiceTable();
        this.teamapptablename = env.getAssocTeamandAppsTable();
        this.endpoint=env.getEndpoint();
        // this.userstatisticsService.updateInsertCount(this.authService.userName(),(err: any, resp:any) => {
        //     if(err){
        //       console.log("error val :",err);
        //     }
        //     else{
        //       console.log("response val :",resp);}
        //   });
      }

      uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }
  insertSplunkConfig(splunkconfig:SplunkConfigModel,callback: Function) {
    this.restservice.insertsplunkconfig(splunkconfig).subscribe( (data) => callback(data),(error)=>callback(error));   
    }
    
    insertSignalfxConfig(signalfxconfig:SignalfxConfigModel,callback: Function) {
        this.restservice.insertsignalfxconfig(signalfxconfig).subscribe( (data) => callback(data),(error)=>callback(error));
        }
        insertNewrelicConfig(newrelicconfig:NewRelicConfigModel,callback: Function){
            this.restservice.insertnewrelicconfig(newrelicconfig).subscribe( (data) => callback(data),(error)=>callback(error));
        }
        insertCloudwatchConfig(cloudwatchconfig:CloudwatchConfigModel,callback: Function) {
            this.restservice.insertcloudwatchconfig(cloudwatchconfig).subscribe( (data) => callback(data),(error)=>callback(error));
        }
        insertTeamAppDtls(teamData:AssociateTeamAppsModel,callback: Function) {
            this.restservice.insertteamapp(teamData).subscribe( (data) => callback(data),(error)=>callback(error));
        }
        insertTeamDtls(teamData:ManageTeamModel,callback: Function) {
                if(teamData.description == ""){
                    teamData.description = null;
                }
                this.restservice.insertteam(teamData).subscribe( (data) => callback(data),(error)=>callback(error));      
        }
        insertServiceDtls(teamData:ManageServiceModel,callback: Function) {
            var ddb = new AWS.DynamoDB.DocumentClient();
                    if(teamData.description == ""){
                        teamData.description = null;
                    }
            this.restservice.insertservice(teamData).subscribe( (data) => callback(data),(error)=>callback(error));
              
            }
        insertUserDtls(userData:ManageUserModel,callback: Function) {
            this.restservice.insertuserdtls(userData).subscribe( (data) => callback(data),(error)=>callback(error));                       
            }
            // updateUserDtls(teamname:string,appname:string,callback:Function) {
            //     console.log("inside update service");
            //     var doc = require('aws-sdk');
            //     var ddb = new AWS.DynamoDB.DocumentClient();
            //     //var ddb = new AWS.DynamoDB.DocumentClient();
            //     var table = this.usertablename;
            //     //var table ="TestTeamAndAssoString";
            //     var params = {
            //          Key: {
            //              emailId: 'sakthiya.priyakrishnaraj@nike.com'         
            //            },  
            //         UpdateExpression: "ADD appNames :val1",
            //         FilterExpression:"contains(#teamName,:val2)",
            //         ExpressionAttributeNames: { 
            //             "#teamName" : "teamName"
            //         },
            //         ExpressionAttributeValues: {
            //             ":val1": ddb.createSet([appname]),
            //             ":val2":teamname
            //         },  
            //           TableName:table,
            //           ReturnValues: "UPDATED_NEW"                    
            //           };         
            //     ddb.update(params, function(err, data) {
            //         console.log(params);
            //         if (err) { 
            //             return console.log(err); 
            //         }
            //         else{
            //             console.log("updated");
            //             callback(err,data);
            //         }
                    
            //     });
            // }
        insertApiConfig(apiconfigdata:OnBoardedAppsModel,callback: Function) {
            //create the ddb object
            var ddb = new AWS.DynamoDB.DocumentClient();
            var desc;
            console.log("Before calling rest ser");
              if(apiconfigdata.description=="")
              {
                apiconfigdata.description=" ";
              }
              console.log(apiconfigdata);
              this.restservice.insertapinames(apiconfigdata).subscribe( (data) => callback(data),(error)=>callback(error));
            }
    // deleteSplunkConfig(splunkconfig:SplunkConfigModel,callback:Function) {
    //         var ddb = new AWS.DynamoDB.DocumentClient();
    //         var table = this.splunkconfigtablename;
    //         var params = {
    //             TableName:table,
    //             Key: {
    //                 'Id': {N:splunkconfig.id.toString()}
    //               }
                  
    //               };
    
    //         ddb.delete(params, function(err, data) {
    //             if (err) { 
    //                 return console.log(err); 
    //             }
    //             else{
    //                 callback(err,data);
    //             }
                
    //         });
    //     }
    
        
}
