// Imports
import { Injectable, Inject } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import * as jwt_decode from "jwt-decode";
import { Environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { FetchcommondataService } from './fetchcommondata.service';
import {RestService} from '../service/rest.service';

// Auth service class implementaion that uses NT Auth Login as it's source.
@Injectable()
export class AuthService {
    private tokenURL: string;
    private callbackRef: Function;
    
    /**
     * Injection constructor
     * @param http Http service
     */
    constructor( private restservice:RestService,
                 private http: Http,
                 private env: Environment,
                 @Inject(Router) private router: Router,
                 @Inject(FetchcommondataService) private commondataservice:FetchcommondataService) {    
                          
    }
    
    /**
     * method to carry out login action
     * @param name string representation of user name
     * @param pwd  string user password
     * @param callback Function to call after token endpoint returns.
     */
    // login(name: string, pwd: string, callback: Function) {

    //     //save a reference to callback function. if it's already set a login call
    //     // is in flight, so just return
    //     if( this.callbackRef ) return;
    //     this.callbackRef = callback;

    //     name = name.toLowerCase();
    //     // Define unencoded credentials string
    //     var assertion = name + ':' + pwd;

    //     // Encode credentials
    //     let encodedAssertion = btoa(assertion);

    //     let headers = new Headers(
    //         {
    //             'Content-Type': 'application/json'
    //         });
    //     let options = new RequestOptions({ headers: headers });
    //     let request_body = {
    //         'grant_type': 'NikeAuth',
    //         'assertion': encodedAssertion,
    //         'appGroupID': '20b93892-fd23-4f44-b934-a3e12eb351e5',//TODO should be parameterized
    //         'appId': 'authManagementUI'
    //          };

    //     this.http.post(this.tokenURL, request_body, options)
    //                 .subscribe((res:Response) => this.extractData(res),
    //                 (err: any) => this.handleError(err));

    // }
    getDecodedAccessToken(token: string): any {
        try{
            return jwt_decode(token);
        }
        catch(Error){
            return null;
        }
      }
    validateLogin(callback: Function) {
        var token =sessionStorage.getItem('okta_token');
        //save a reference to callback function. if it's already set a login call
        // is in flight, so just return
        if( this.callbackRef ) return;
        this.callbackRef = callback;
       
      let tokenInfo = this.getDecodedAccessToken(token); 
      //console.log(" token error :",tokenInfo );
      //if tokenInfo is null then there is error returned from OKTA
      if(tokenInfo == null){
        this.callbackRef("Please contact #retail_sro slack channel for access ");
        this.callbackRef = null;
      }
      else{
            //setting expiration token as equilant milli seconds value. 
        var expiration = tokenInfo.exp;
        expiration = (expiration * 1000);
        sessionStorage.setItem('loggedInFlag','true');
        sessionStorage.setItem('expiration',expiration);
        sessionStorage.setItem('email',tokenInfo.sub);
        sessionStorage.setItem('roles',tokenInfo.groups);
        this.callbackRef(true);
        this.callbackRef = null;
      }
      

    }
    private extractData(res: Response) {
        let body = res.json();

        var expiration = body.expires_in - 10;
        expiration = (expiration * 1000) + Date.now();
        var roles = body.roles.toString();

        var nameArr: Array<string> = body.ldap_name.split(",");
        nameArr.reverse();

        sessionStorage.setItem('loggedInFlag','true');
        sessionStorage.setItem('access_token',body.access_token);
        sessionStorage.setItem('refresh_token',body.refresh_token);
        sessionStorage.setItem('expiration',expiration.toString());
        sessionStorage.setItem('email',body.email);
        sessionStorage.setItem('user',nameArr.join(" "));
        sessionStorage.setItem('roles',roles);

        this.callbackRef(true);
        this.callbackRef = null;
    }

    private handleError (error: Response | any) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            console.log("got an error Response")
            const body = error.json() || '';
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            console.log("got some other error")
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        this.callbackRef(false);
        this.callbackRef = null;
    }

    validateUserAccessLevel(callback:Function):any{
        this.commondataservice.getUserAccessData(this.userEmail(),(serresp: any) => {
          let body: any;
          if(serresp.statusCode>400){
            return 'Error'
          }
          else{
            var index = 0;
            var res=serresp.body; 
            if(serresp.statusCode<=210){
                if(res!=undefined){
                    sessionStorage.setItem('team',res.team_name);
                    sessionStorage.setItem('appNames',res.app_names);
                    callback(true);
                }
                else{
                    callback(false);
                }
            }
            else{
                callback(false);
            }
          }
        });
      }

    /**
     * logout method. removes all session data that represents logged in state
     */
    logout() {
        sessionStorage.removeItem('loggedInFlag');
        // sessionStorage.removeItem('access_token');
        // sessionStorage.removeItem('refresh_token');
        sessionStorage.removeItem('expiration');
        sessionStorage.removeItem('sub');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('roles');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('roles');
        sessionStorage.removeItem('team');
        sessionStorage.removeItem('appNames');
        localStorage.removeItem('code_verifier');
    }

    /**
     * isLoggedIn returns where user is logged in. Imagin that.
     */
    isLoggedIn(): Boolean {
        var loggedInFlag = sessionStorage.getItem('loggedInFlag');
        return ( undefined == loggedInFlag ) ? false : true;
    }

    /**
     * hasRole checks if logged in user has passed in role
     * @para role   Role to be checked
     */
    hasRole(role: string): boolean {
        var rolesString = sessionStorage.getItem('roles');
        var roles: Array<string> = rolesString.split(",");
        return (roles.indexOf(role) != -1);
    }

    /**
     * canManageKeys is used to if a user is alloed into Key Management portion of app
     */
    canManageKeys(): boolean {
        return this.hasRole("apiauth.key_mgr");//TODO set this string somewhere else(?)
    }

    /**
     * canManageLogins is used to check if a user is alloed into Login Management portion of app
     */
    canManageLogins(): boolean {
        return this.hasRole("apiauth.login_mgr");//TODO  set this string somewhere else(?)
    }

    /**
     * Returns true if role assigned to preform lockout management
     */
    canManageLockout(): boolean {
        return this.hasRole("api.jwkms.revokeMgr");//TODO  set this string somewhere else(?)
    }
    
    isReadOnlyUser():boolean{
        return this.hasRole("Application.SRO.ReadOnly");
    }
    isUser():boolean{
        return this.hasRole("Application.SRO.Users");
    }
    /**
     * Returns true if role is assigned for super admin.
     */
    isSuperAdmin(): boolean {
        return this.hasRole("Application.SRO.Admins");//TODO  set this string somewhere else(?)
    }

    /**
     * userEmail returns logged in user's email
     */
    userEmail(): string {
        return sessionStorage.getItem('email');
    }

    appNames(): string{
        return sessionStorage.getItem('appNames');
    }
    teamName(): string{
        return sessionStorage.getItem('team');
    }
    setAppNames(appNames:any){
        sessionStorage.setItem('appNames',appNames);
    }

    /**
     * userName returns name of logged in user, "First Last"
     */
    userName(): string {
        return sessionStorage.getItem('user');
    }
    // transformDate(myDate):string {
    //     console.log("myDate :",myDate);
    //     return this.datepipe.transform(myDate, 'yyyyMMddhh'); //whatever format you need. 
    //   }

     /**
     * validate the session and redirect the user to main page.
     */
    isValidSession(callback: Function){
        if( !this.isLoggedIn() ) {
            callback("Not Logged In");
            return;
        }
        var expiration = Number(sessionStorage.getItem('expiration'));
      
        if(Date.now() < expiration) {
            callback(true);
            return;
        }
        else{
            callback(false);
        }
    }
    /**
     * token returns the current access token, or error if refresh token has expired
     * @param   callback function to either return access token or error state
     *
     * if current access token has expired to method attempts the refresh call to get a
     *   new access token. If the refresh fails, user is logged out and mush re-authenticate.
     */
    token(callback: Function) {
        if( !this.isLoggedIn() ) {
            callback("Not Logged In");
            return;
        }
        var expiration = Number(sessionStorage.getItem('expiration'));
        //if access token is not expired, return access token
        if(Date.now() < expiration) {
            callback(null,sessionStorage.getItem('access_token'));
            return;
        }

        let headers = new Headers(
            {
                'Content-Type': 'application/json'
            });
        let options = new RequestOptions({ headers: headers });
        let request_body = {
            'grant_type': 'NikeAuthRefresh',
            'access_token': sessionStorage.getItem('access_token'),
            'refresh_token': sessionStorage.getItem('refresh_token'),
            'appId': 'authManagementUI'
             };

        this.http.post(this.tokenURL, request_body, options)
                    .subscribe((res:Response) => this.onRefreshSuccess(res,callback),
                    (err: any) => this.onRefreshError(err,callback));
    }


    private onRefreshSuccess(res: Response,callback: Function) {
        let body = res.json();

        var expiration = body.expires_in - 10;
        expiration = (expiration * 1000) + Date.now();
        var roles = body.roles.toString();

        sessionStorage.setItem('loggedInFlag','true');
        sessionStorage.setItem('access_token',body.access_token);
        sessionStorage.setItem('refresh_token',body.refresh_token);
        sessionStorage.setItem('expiration',expiration.toString());
        sessionStorage.setItem('roles',roles);

        callback(null,body.access_token);
    }

    private onRefreshError (error: Response | any, callback: Function) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            console.log("got an error Response")
            const body = error.json() || '';
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            console.log("got some other error")
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        this.logout();
        callback("Access Expired");
    }

    //to check whether the token expired
    isTokenExpired():boolean{
        var expiration = Number(sessionStorage.getItem('expiration'));
    
        if(Date.now() < expiration) {
            return true;
        }
        else{
            return false;
        }
    }
    expresspost(bodyparams,callback:Function){
        
        this.restservice.expresspostdata(bodyparams).subscribe( (data) => callback(data));
    }

}